
import Component from "vue-class-component";
import {Emit, Prop, Vue} from "vue-property-decorator";

@Component
export default class AtomsButtonDanger extends Vue {
  @Prop({type: Boolean, required: false, default: false})
  private large!: boolean;
  @Prop({type: Boolean, required: false, default: false})
  private disabled!: boolean;

  @Emit('click')
  private handleClick() {
    // イベント発火のみ
  }
}
