import { render, staticRenderFns } from "./RecruitBodyHolidayComponent.vue?vue&type=template&id=248e1b84&scoped=true"
import script from "./RecruitBodyHolidayComponent.vue?vue&type=script&lang=ts"
export * from "./RecruitBodyHolidayComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248e1b84",
  null
  
)

export default component.exports