
import {Component, Vue} from "vue-property-decorator";

@Component
export default class HomeBodyClientComponent extends Vue {
  // data
  private clientList = [
    {
      alt: "株式会社アクティア logo",
      src: "/home/client/actier-logo-color.png",
      srcBlackColor: "/home/client/actier-logo-black.png",
    },
    {
      alt: "イージーソフト株式会社 logo",
      src: "/home/client/ezsoft-logo-color.png",
      srcBlackColor: "/home/client/ezsoft-logo-black.png",
    },
    {
      alt: "HRソリューションズ株式会社 logo",
      src: "/home/client/hr-solutions-logo-color.jpg",
      srcBlackColor: "/home/client/hr-solutions-logo-black.jpg",
    },
    {
      alt: "有限会社システム設計 logo",
      src: "/home/client/system-design-logo-color.gif",
      srcBlackColor: "/home/client/system-design-logo-black.gif",
    },
    {
      alt: "株式会社システムリサーチ logo",
      src: "/home/client/system-research-logo-color.png",
      srcBlackColor: "/home/client/system-research-logo-black.png",
    },
    {
      alt: "富士通株式会社 logo",
      src: "/home/client/fujitsu-logo-color.png",
      srcBlackColor: "/home/client/fujitsu-logo-black.png",
    },
  ];
}
