import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=76d3d292&scoped=true"
import script from "./Message.vue?vue&type=script&lang=ts"
export * from "./Message.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d3d292",
  null
  
)

export default component.exports