
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import MoleculeAsideDrawer from "@/components/UI/molecules/MoleculeAsideDrawer.vue";
import MoleculeAsideToggle from "@/components/UI/molecules/MoleculeAsideToggle.vue";
import MoleculeHeader from "@/components/UI/molecules/MoleculeHeader.vue";
import {CommonNavItemType} from "@/type/common/nav";

@Component({
  components: {
    MoleculeAsideDrawer,
    MoleculeAsideToggle,
    MoleculeHeader,
  },
})
export default class OrganismHeaderAndAside extends Vue {
  // prop
  @Prop({required: true})
  private navItemsTop!: Record<string, CommonNavItemType>;
  @Prop({required: true})
  private navItemsDown!: Record<string, CommonNavItemType>;
  @Prop({required: true, default: false})
  private drawer!: boolean;

  // emit
  @Emit()
  private toggleDrawer() {
    // イベント発火のみ
  }
  @Emit()
  private redirectToHomePage(): void {
    // イベント発火のみ
  }
  @Emit()
  private openDrawer() {
    // イベント発火のみ
  }
  @Emit()
  private closeDrawer() {
    // イベント発火のみ
  }
}
