
import {Component, Vue} from "vue-property-decorator";

@Component
export default class HomeTopMainComponent extends Vue {
  private scrollToServiceIntroduce() {
    const serviceIntroduce = document.getElementsByClassName("home-service-introduce-component").item(0);
    if (!serviceIntroduce) {
      return;
    }
    serviceIntroduce.scrollIntoView({behavior: "smooth"});
  }
}
