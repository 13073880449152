
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class AtomCardOnlyText extends Vue {
  @Prop({type: Boolean, required: false, default: false})
  private flat!: boolean;
  @Prop({type: Boolean, required: false, default: false})
  private textLeft!: boolean;
  @Prop({type: Boolean, required: false, default: false})
  private textCenter!: boolean;
  @Prop({type: Boolean, required: false, default: false})
  private textRight!: boolean;
  @Prop({type: Number, required: false, default: 100})
  private widthPercent!: number;

  private get getWidthPercent(): string {
    return `${this.widthPercent}%`;
  }
}
