
import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
import RecruitBodyMainImageComponent from "@/components/recruit/body/RecruitBodyMainImageComponent.vue";
import RecruitBodyJobDescriptionComponent from "@/components/recruit/body/RecruitBodyJobDescriptionComponent.vue";
import RecruitBodyQualificationRequirementComponent from "@/components/recruit/body/RecruitBodyQualificationRequirementComponent.vue";
import RecruitBodyBackgroundComponent from "@/components/recruit/body/RecruitBodyBackgroundComponent.vue";
import RecruitBodyWorkspaceLocationComponent from "@/components/recruit/body/RecruitBodyWorkspaceLocationComponent.vue";
import RecruitBodyOfficeHourComponent from "@/components/recruit/body/RecruitBodyOfficeHourComponent.vue";
import RecruitBodySalaryComponent from "@/components/recruit/body/RecruitBodySalaryComponent.vue";
import RecruitBodyHolidayComponent from "@/components/recruit/body/RecruitBodyHolidayComponent.vue";
import RecruitBodyWelfareComponent from "@/components/recruit/body/RecruitBodyWelfareComponent.vue";
import RecruitBodyTemporaryMynaviComponent from "@/components/recruit/body/RecruitBodyTemporaryMynaviComponent.vue";

@Component({
  components: {
    RecruitBodyTemporaryMynaviComponent,
    RecruitBodyWelfareComponent,
    RecruitBodyHolidayComponent,
    RecruitBodySalaryComponent,
    RecruitBodyOfficeHourComponent,
    RecruitBodyWorkspaceLocationComponent,
    RecruitBodyBackgroundComponent,
    RecruitBodyQualificationRequirementComponent,
    RecruitBodyJobDescriptionComponent,
    RecruitBodyMainImageComponent
  },
})
export default class RecruitBodyComponent extends Vue {
}
