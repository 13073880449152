
import {Component, Vue} from "vue-property-decorator";
import HomeBodyServiceIntroduceComponent from "@/components/home/body/HomeBodyServiceIntroduceComponent.vue";
import HomeBodyProfileComponent from "@/components/home/body/HomeBodyProfileComponent.vue";
import HomeBodyClientComponent from "@/components/home/body/HomeBodyClientComponent.vue";

@Component({
  components: {
    HomeBodyClientComponent,
    HomeBodyProfileComponent,
    HomeBodyServiceIntroduceComponent,
  },
})
export default class HomeBodyComponent extends Vue {
}
