
import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
import AtomButtonOk from "@/components/UI/atoms/button/AtomButtonOk.vue";
import AtomButtonMove from "@/components/UI/atoms/button/AtomButtonMove.vue";
import AtomButtonCancel from "@/components/UI/atoms/button/AtomButtonCancel.vue";
import AtomButtonDanger from "@/components/UI/atoms/button/AtomButtonDanger.vue";
import AtomIconHamburgerMenu from "@/components/UI/atoms/icon/AtomIconHamburgerMenu.vue";

@Component({
  components: {
    AtomIconHamburgerMenu,
    AtomButtonDanger,
    AtomButtonCancel,
    AtomButtonMove,
    AtomButtonOk,
  }
})
export default class DesignAtoms extends Vue {
}
