
import {Component, Emit, Vue} from "vue-property-decorator";
import AtomLogoValueHeader from "@/components/UI/atoms/logo/AtomLogoValueHeader.vue";
import AtomIconHamburgerMenu from "@/components/UI/atoms/icon/AtomIconHamburgerMenu.vue";

@Component({
  components: {
    AtomLogoValueHeader,
    AtomIconHamburgerMenu,
  },
})
export default class MoleculeHeader extends Vue {
  @Emit()
  private toggleDrawer() {
    // イベント発火のみ
  }

  @Emit()
  private redirectToHomePage(): void {
    // イベント発火のみ
  }
}
