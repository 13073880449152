
import {Component, Prop, Vue} from "vue-property-decorator";
import MoleculeFooter from "@/components/UI/molecules/MoleculeFooter.vue";

@Component({
  components: {
    MoleculeFooter,
  }
})
export default class OrganismFooter extends Vue {
  @Prop({type: Number, required: true})
  year!: number;
}
