
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {CommonNavItemType} from "@/type/common/nav";

@Component
export default class MoleculeAsideToggle extends Vue {
  // prop
  @Prop({required: true})
  private navItemsTop!: Record<string, CommonNavItemType>;
  @Prop({required: true})
  private navItemsDown!: Record<string, CommonNavItemType>;
  @Prop({required: true, default: false})
  private drawer!: boolean;

  private toggleDrawer(event) {
    // parentのtoggleDrawerを動かす時も動いてしまうが、別componentにする為、仕方ない
    if (event) {
      this.openDrawer();
    } else {
      this.closeDrawer();
    }
  }

  @Emit()
  private openDrawer() {
    // イベント発火のみ
  }

  @Emit()
  private closeDrawer() {
    // イベント発火のみ
  }
}
