
import {Component, Vue} from "vue-property-decorator";
import MessageHeaderComponent from "@/components/message/header/MessageHeaderComponent.vue";
import MessageBodyComponent from "@/components/message/body/MessageBodyComponent.vue";
import MessageFooterComponent from "@/components/message/footer/MessageFooterComponent.vue";
import TemplateHeaderAndAside from "@/components/UI/templates/TemplateHeaderAndAside.vue";
import TemplateFooter from "@/components/UI/templates/TemplateFooter.vue";

@Component({
  components: {
    TemplateFooter,
    TemplateHeaderAndAside,
    MessageFooterComponent,
    MessageBodyComponent,
    MessageHeaderComponent,
  },
})
export default class Message extends Vue {
}
